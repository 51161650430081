
.user-profile {
  min-height: 95vh;
  // Desktop
  @media (min-width: $desktop-breakpoint) {
    min-height: unset;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;

    // Desktop
    @media (min-width: $desktop-breakpoint) {
      font-size: 48px;
    }
  }

  .form-container {
    margin-top: 1rem;

    .form-label {
      color: $primary-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    .form-control {
      font-size: 16px;
      font-weight: 400;
      background-color: #EDF0F7;
    }

    .form-details {
      font-size: 11px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 0.5rem;
    }

    .change-password-modal-toggle-btn {
      position: absolute;
      right: 20px;
      margin-top: 5px;
    }

    .delete-account-link a {
      color: $danger-red;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin-top: 1rem;
    }
  }

  .modal {
    padding-top: 25vh;

    .modal-content {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 10px;

      @media (min-width: $desktop-breakpoint) {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    .modal-header {
      border-bottom: none;

      .modal-title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .modal-footer {
      border-top: none;
    }

    .password-input {
      display: flex;

      .slash-icon {
        position: absolute;
        right: 7%;
        margin-top: 3%;
      }
    }
  }
}
