$primary-color: #2D3648;
$secondary-color: #CBD2E0;
$inactive-link: #717D96;
$border-gray: #D5D5D5;
$lighter-gray: #5F6673;
$danger-red: #BB534F;
$error-red: #FF3838;

$desktop-breakpoint: 576px;

.new-theme {
  font-family: 'Inter', sans-serif;

  .form-control {
    font-size: 14px !important;
  }

  .decoration-none {
    text-decoration: none !important;
  }

  .btn {
    border-radius: 6px;
    font-weight: 400;

    // Remove the blue highlight when clicked
    &:focus, &:active {
      outline: none !important;
      box-shadow: none !important;
    }

    // Mobile
    &.btn-sm {
      font-size: 12px
    }

    // Desktop
    &.btn-md {
      font-size: 14px;
    }

    &.pad-x {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.pad-x-more {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &.btn-outline-lighter {
      color: $inactive-link;
      border-color: $border-gray;

      &:hover {
        color: white;
        background-color: $inactive-link;
        border-color: $inactive-link;
      }

      &.no-hover {
        &:hover {
          color: $inactive-link;
          background-color: white;
          border-color: $border-gray;
        }
      }
    }

    &.btn-dark {
      &:hover {
        color: $primary-color;
        background-color: white;
        border-color: $primary-color;
      }
    }

    &.btn-outline-dark {
      background-color: white;

      &:hover {
        color: white;
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }

    &.collapse-btn {
      border-radius: 0;
      background-color: $border-gray;
      width: 50px;
      padding: 1px;
      border-radius: 3px;
    }
  }

  .text-primary {
    color: $primary-color;
  }

  .text-lighter {
    color: $lighter-gray;
  }

  .text-danger {
    color: $danger-red;
  }

  .collection-tabs {
    border-bottom: 1px solid $primary-color;

    .tab-link {
      color: $inactive-link; // Default to inactive state
      font-weight: 500;
      text-decoration: none;

      // Mobile
      font-size: 12px;
      line-height: 24px;

      // Desktop
      @media (min-width: $desktop-breakpoint) {
        font-size: 14px;
        line-height: 18px;
      }

      &.active {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
      }

      &.thirds {
        width: 33.33%;
        text-align: center;
      }

      &.halves {
        width: 50%;
        text-align: center;
      }

      &.fifths {
        width: 20%;
        text-align: center;
      }
    }
  }

  .collection-list {
    .list-item {
      border-bottom: 1px solid $border-gray;

      // Desktop
      @media(min-width: $desktop-breakpoint) {
        // Set a fixed height since there are two columns. There
        // are instances where very long titles can make the list
        // items taller and uneven when side-by-side.
        height: 120px;
      }

      .item-title {
        color: $primary-color;
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;

        // Desktop
        @media (min-width: $desktop-breakpoint) {
          font-size: 16px;
        }

          a {
            color: inherit; // Inherit color from .item-title
            text-decoration: inherit; // Inherit text-decoration from .item-title
          }
        }

      .item-links {
        max-width: 25%;

        .dropdown {
          a {
            text-decoration: none;
          }

          .dropdown-menu {
            border-color: $lighter-gray;
            border-radius: 4px;

            a {
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }

      .item-body {
        font-weight: 400;
        color: $lighter-gray;

        .by-line-text {
          font-size: 10px;
          font-style: italic;
          line-height: 13px;
          margin-bottom: 0;
          padding-bottom: .5rem;
        }

        .place-count-text, .view-count-text {
          font-size: 12px;
          line-height: 18.2px;
          margin-bottom: 0;

          // Desktop
          @media (min-width: $desktop-breakpoint) {
            font-size: 13px;
            line-height: 16.9px;
          }
        }
      }
    }

    .no-items-text {
      font-size: 14px;

      // Desktop
      @media (min-width: $desktop-breakpoint) {
        font-size: 16px;
      }
    }
  }

  .pagination-dark {
    // Remove outline when clicked
    .page-link:focus, .page-link:active {
      outline: none !important;
      box-shadow: none !important;
    }

    .page-item {
      &.disabled {
        .page-link {
          background: white;
          color: $inactive-link;
          border-color: $inactive-link;
        }
      }

      &.active {
        .page-link {
          background: $primary-color;
          color: white;
        }
      }

      .page-link {
        background: white;
        color: $primary-color;
        border-color: $primary-color;

        :hover {
          background: $primary-color;
          color: white;
        }
      }
    }
  }

  .overflow-auto {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .overflow-auto::-webkit-scrollbar {
    display: none;
  }
}

// Import here to override bootstrap styles above
@import './page_header.scss';
@import './my_stories.scss';
@import './navbar.scss';
@import './story_show.scss';
@import './place_show.scss';
@import './user_profile.scss';
@import './saved_stories.scss';
@import './recommended_places.scss';
@import './dropdown_filters.scss';

// Add breakpoint functionality to bootstrap width properties. e.g. .w-md-50, .w-lg-100
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    $sizes: (25: 25%, 30: 30%, 33: 33%, 50: 50%, 75: 75%, 100: 100%);
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {width: $length !important;}
    }
  }
}
