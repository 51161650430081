.new_navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 5px 7px -4px rgba(0, 0, 0, 0.1);

  height: 4rem;
  z-index: 1000;

  @media (max-width: 1000px) {
    height: 6rem; //small-screen-nav-fix;
  }

  .navbar-toggler {
    border: none;
  }

  &.fixed-bottom {
    z-index: 2;
  }
}

.remove-scrollbar {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#menu-dropdowns {
  z-index: 999;
  position: fixed;
  top: 4rem;
  background-color: white;
  box-shadow: 0px 5px 7px -4px rgba(0, 0, 0, 1);
  background-color: white;
  width: fit-content;
  border-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  a {
    // allow wrapping
    white-space: normal;
  }

  //  medium and down
  @media (max-width: 1000px) {
    top: 6rem;
    width: fit-content
  }
}

.bold-link.active {
  font-weight: bold;
  text-decoration: underline;
}