.story-show {
  .fixed-height {
    // Compensate for the fixed header
    height: calc(100vh - 8rem);

    &.scrollable {
      overflow-y: auto;
    }
  }

  // If screen is small, then make the map container 50vh
  @media (max-width: 768px) {
    .fixed-height {
      height: calc(50vh - 4rem);
    }
  }

  .rounded-top-border {
    border-top: 1px solid black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rounded-top-border-only-on-small-screens {
    @media (max-width: 768px) {
      border-top: 1px solid black;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-top: 1rem;
      margin: 1rem;
    }
  }

  .checkbox-input-group {
    border: 1px solid $secondary-color;
    border-radius: 4px;
    height: 100%;
    text-decoration: none;
    color: $secondary-color;
    color: $primary-color;
    margin: 0;
    :hover, :active {
      background-color: $secondary-color;
      color: white;
    }
    .place-title {
      padding: 4px 8px;
      color: #007bff;
      cursor: pointer;
    }
  }

  #place-count-div {
    background-color: aqua;
    font-weight: bold;
    padding: .5rem;
  }

  .place-list {
    list-style-type: none;
  }

  .checkbox-container {
    padding-left: 3em;
    text-indent: -1em;
  }

  .scrollable {
    overflow-y: auto;
  }

  .only-scrollable-on-big-screens {
    @media (min-width: 768px) {
      overflow-y: auto;
    }
  }

  .vh-20 {
    max-height: 20vh;
  }
  //  hide on bigger screens
  .only-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .fixed-height-only-on-big-screens {
    @media (min-width: 768px) {
      height: calc(100vh - 8rem);
    }
  }
}
