.custom-sidebar {
	position: relative;
	width: 100%;
	overflow-y:auto;
	overflow:auto; 
	height: 90vh;
}
.custom-sidebar ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.custom-sidebar ul li {
  /* Sub Menu */
}
.custom-sidebar .menu-item {
	cursor: pointer;
	padding: 10px 0 10px 10px;
}
.custom-sidebar ul li a {
	display: block;
	background: #ebebeb;
	padding: 10px 15px;
	color: #333;
	text-decoration: none;
	-webkit-transition: 0.2s linear;
	-moz-transition: 0.2s linear;
	-ms-transition: 0.2s linear;
	-o-transition: 0.2s linear;
	transition: 0.2s linear;
}
.custom-sidebar ul li a:hover {
	background: #f8f8f8;
	color: #515151;
}
.custom-sidebar ul li .fa {
	width: 16px;
	text-align: center;
	margin-right: 5px;
	float:right;
}
.custom-sidebar ul ul {
	display: none;
	background-color:#ebebeb;
}
.custom-sidebar ul li ul li a {
	background: #f8f8f8;
	border-left: 4px solid transparent;
	padding: 10px 20px;
}
.custom-sidebar ul li ul li a:hover {
	background: #ebebeb;
	border-left: 4px solid #3498db;
}