html,
body {
  // height: 100%;  Don't do this since it causes the page to scroll to not work when clicking on the back button.
  min-height: 100%;
}

// site-wide stylings/overwrites
.input-group {
  z-index: 0;
}

#error_explanation {
  h2 {
    font-size: 1rem;
    color: red;
    text-align: center;
  }
}

//Hides Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

/* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }
.bg-secondary-light {
  background-color: #E0E0E0;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.blue-link {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .item {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.no-text-decoration {
  text-decoration: none !important;
}


@media print {
  body.print-page {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    left: 0;
    right: 0;
    top: 1cm;
  }
}

.vh-100 {
  height: 100vh;
}

$custom-colors: (
  "custom-gray": rgba(186, 186, 186, 0.275),
  "light-gray": rgb(172, 48, 48)
);
$inactive-link: #717D96;

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

.page-link {
  z-index: 1 !important;
}

.breadcrumbs {
  color: $inactive-link;
  font-size: 14px;

  a {
    color: $inactive-link;
  }

  i {
    margin-left: .5rem;
    font-size: 10px;
  }
}

.nowrap-text {
  white-space: nowrap;
}

.bold-link {
  font-weight: 500;
  &:hover {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

// .hidden-on-small-screen {
//   display: block; // Default display for larger screens
// }

// .show-on-small-screen {
//   display: none; // Default display for larger screens
// }

@media (max-width: 1000px) {
  .hidden-on-small-screen {
    display: none;
  }

  // .show-on-small-screen {
  //   display: block;
  // }
}

@media (min-width: 1000px) {
  // .hidden-on-small-screen {
  //   display: block;
  // }

  .show-on-small-screen {
    display: none;
  }
}

.horizontal-scroll-nav {
  overflow: auto;
  white-space: nowrap;

  @media (max-width: 400px) {
    width: 90%;
  }
}