.page-header {
  color: $primary-color;

  h1 {
    // Both
    font-weight: 600;

    // Mobile
    font-size: 20px;
    line-height: 24.2px;

    // Desktop
    @media (min-width: 431px) {
      font-size: 48px;
      line-height: 58.09px;
    }
  }

  .sub-header-text {
    // Both
    font-style: italic;
    font-weight: 400;

    // Mobile
    font-size: 10px;
    line-height: 15px;

    // Desktop
    @media (min-width: 431px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
