.story-form {
  border: 1px solid black;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .header {
    h4 {
      font-weight: normal;
    }

    #logo {
      max-width: 300px;
    }
  }

  .incomplete-form-item {
    background-color: yellow;
  }

  .complete-form-item {
    background-color: lightgreen;
  }
}

.story-form-nav-btns {
  .active-item {
    background: lightgrey !important
    ;
    font-weight: 500 !important;
    color: black impo !important;
  }
}
