@import '../styles/main.scss';
@import '../styles/new_theme/main.scss';

// Only in dev mode to see the turbo-frame border
// turbo-frame {
//   display: block;
//   border: 1px solid blue;
// }

#sap-body .modal-backdrop {
  z-index: 1;
}

.modal-content {
  z-index: 4;
}

// Remove default anchor styles
.remove-anchor-styles {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
