.my-stories-new,
.my-stories-edit {
  .fixed-height {
    // Compensate for the fixed header
    height: calc(100vh - 8rem);

    &.scrollable {
      overflow-y: auto;
    }
  }

  nav.fixed-bottom {
    z-index: 1;
  }

  .rounded-border {
    border-radius: 8px;
  }

  .main-form-container {
    background: $inactive-link;
  }

  .rounded-top-border {
    border-top: 1px solid black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .border-radius-10 {
    border-radius: 10px;
  }

  .rounded-top-border-only-on-small-screens {
    @media (max-width: 768px) {
      border-top: 1px solid black;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-top: 1rem;
      margin: 1rem;
    }
  }

  .scrollable {
    overflow-y: auto;
  }

  .only-scrollable-on-big-screens {
    @media (min-width: 768px) {
      overflow-y: auto;
    }
  }

  .vh-20 {
    max-height: 20vh;
  }

  .only-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .only-desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .fixed-height-only-on-big-screens {
    @media (min-width: 768px) {
      height: calc(100vh - 8rem);
    }
  }

  .main-form-container {
    background: $inactive-link;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
  }
  .form-container {
    margin-top: 1.5rem;

    .form-label {
      color: $primary-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    .form-control {
      font-size: 11px;
    }

    .input-char-count-text {
      margin-top: .5rem;
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      text-align: right;
      color: $inactive-link;
    }

    .places-count-text {
      margin-top: .5rem;
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      font-style: italic;
      color: $inactive-link;
    }

    .input-description-text {
      margin-bottom: .5rem;
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      color: $inactive-link;
    }

    .checkbox-input-group {
      border: 1px solid $primary-color;
      padding: 4px 8px;
      border-radius: 4px;
      height: 30px;

      input {
        height: auto;
        margin-right: .35rem;
      }

      label {
        height: auto;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .modal-btn {
    color: black;
  }

  .modal-backdrop {
    z-index: 10;
  }

  .exit-modal {
    padding-top: 25vh;

    .modal-content {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .modal-header {
      border-bottom: none;

      .modal-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.01em;
        text-align: center;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        width: 255px;
        height: 50px;
      }
    }
  }

  .place-block {
    .place-details {
      border: 1px solid $primary-color;
      border-radius: 8px;
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .place-name {
        font-size: 12px;
      }

      .place-urls {
        max-width: 50%;

        img {
          width: 20px;
        }
      }
    }

    button {
      max-width: 10%;
      color: $lighter-gray;
    }
  }

  .display-error {
    input {
      border: 2px solid #FF3838;
    }

    .btn {
      color: #FF3838;
      border: 2px solid #FF3838;
    }
  }

  .invalid-feedback {
    &.display-error {
      background-color: white;
      position: absolute;
      display: unset;
    }

    // "Borrowed" from: https://nicolasgallagher.com/pure-css-speech-bubbles/demo/
    &.triangle-border {
      font-size: 10px;
      font-style: italic;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      padding: 8px;
      border: 2px solid #FF3838;
      margin: 1em 0 3em;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      width: auto;

      &:before {
        content: "";
        position: absolute;
        border-width: 20px 20px 0;
        border-style: solid;
        border-color: #FF3838 transparent;
        display: block;
        width: 0;
      }

      &:after {
        content: "";
        position: absolute;
        border-width: 13px 13px 0;
        border-style: solid;
        border-color: #fff transparent;
        display: block;
        width: 0;
      }
    }

    &.top-left {
      &:before {
        top: -12px;
        bottom: auto;
        left: 20px;
        border-width: 0 11px 12px;
      }

      &:after {
        top: -9px;
        bottom: auto;
        left: 19px;
        border-width: 0 12px 13px;
      }
    }
  }

  .my-story-map-container {
    height: 100%;
    z-index: 1;
  }

  .map-collapse-btn {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }

  .obfuscator {
    position: absolute;
    background-color: white;
    height: 110vh; // Add a little extra so we can scroll past bottom nav to select categories
    width: 100%;
    z-index: 0;
  }

  .desktop {
    label {
      font-size: 14px;
      font-weight: 600;
    }

    .input-char-count-text,
    .places-count-text {
      font-size: 12px;
    }

    .input-description-text {
      font-size: 14px;
    }

    .divider {
      border-bottom: .5px solid $inactive-link;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .force_hidden {
      display: none !important;
    }

    .nav-buttons {
      // hidden by default
      .btn {
        width: 19vw;
        height: 48px;
        font-size: 14px;
      }

      .btn-details {
        text-align: center;
        font-size: 11px;
        color: $inactive-link;
        margin-bottom: 0;
      }
    }

    .place-block {
      width: 100%;
    }

    .place-comment {
      width: 100%;
    }

    .my-story-map-container-desktop {
      height: 100%;
      z-index: 1;

      #desktopMap {
        height: 100%;
        z-index: 1;
      }
    }
  }
}