
.dropdown-page {
  // Allow the dropdown to be visible on the page when no results are present
  min-height: 500px;
}

.dropdown {
  &.show {
    .filters-btn {
      border-color: #9747FF;
    }
  }

  .dropdown-menu {
    width: 315px;

    .submenu-options {
      max-height: 315px;
      overflow: auto;
    }

    .row {
      margin: unset
    }

    .dropdown-item {
      font-size: 12px;
      background-color: white;
      color: #000;
      // allow wrapping of text
      white-space: normal;

      a {
        color: unset;
        padding-bottom: 5px;
        padding-right: 10px;
        padding-left: 5px;
        text-decoration: none;
        width: 105%;
      }

      i {
        font-size: 14px;
      }

      &.active, :active {
        a {
          color: #9747FF;
          border-bottom: 1px solid #9747FF;
        }
      }
    }

    .checkbox-input-group {
      font-size: 12px;
      margin: 0;

      input {
        margin-right: .5rem;
      }
    }

    .reset-link {
      font-size: 12px;
      color: $inactive-link;
      text-decoration: none;
    }
  }
}

.selected-filters {
  i {
    vertical-align: middle;
    font-size: 14px;
  }
}
