.saved-stories {
  .collection-list {
    .list-item {
      // Desktop
      @media (min-width: $desktop-breakpoint) {
        height: 170px;
      }
      .item-links {
        max-width: 30%;
      }
    }
  }
}
