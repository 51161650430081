#wrapper {
  overflow-x: hidden;
  height: 100%;
}

#sap-logo {
  max-width: 233px;
}

#wrapper.sb-closed {
  #page-content-wrapper {
    height: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  #sidebar-wrapper {
    margin-left: -15rem;
  }
}

#page-content-wrapper {
  height: 100%;
  z-index: -1;
  margin-left: 0rem;
  margin-top: 4rem;

  // make margin top 8rem if greater smaller than 1000px
  @media (max-width: 1000px) {
    margin-top: 6rem; //small-screen-nav-fix
  }

  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#main-content {
  min-height: 100vh;
  // height: 100vh; Don't do this since it causes the page to scroll to not work when clicking on the back button.
}

.fixed-bottom {
  padding: 1rem;
  position: fixed;
  bottom: 3rem;
  width: 100%;
  height: fit-content;
}

.dropdown-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Ensure it appears above other content */
}

#sidebar-wrapper {
  margin-top: 4rem;

  // make margin top 8rem if greater smaller than 1000px
  @media (max-width: 1000px) {
    margin-top: 6rem; //small-screen-nav-fix
  }

  z-index: 3;
  position: fixed;
  min-height: 100vh;
  margin-left: 0;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;

  .sidebar {
    box-shadow: 7px 0 10px -8px rgba(0, 0, 0, 0.2);

    .list-group-item {
      padding: .5rem 1.25rem;

      &:hover {
        color: black;
      }
    }

    .active-item {
      background: lightgrey !important;
      font-weight: 500 !important;
      color: black impo !important;
    }
  }

  .list-group {
    width: 15rem;
    height: 100vh;
  }
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px;
}

// DESKTOP STYLES ABOVE 1000px
@media (min-width: 1000px) {
  .admin-screen {
    #page-content-wrapper {
      z-index: none;
      margin-left: 15rem;
      margin-top: 4rem;

      // make margin top 8rem if greater smaller than 1000px
      @media (max-width: 1000px) {
        margin-top: 6rem; //small-screen-nav-fix
      }
    }

    #sidebar-wrapper {
      margin-left: 0;
    }

    #wrapper.sb-closed {
      #sidebar-wrapper {
        margin-left: -15rem;
      }
    }
  }
}

// DESKTOP STYLES BELOW 1000px
@media (max-width: 999px) {
  #wrapper {

    // remove clicking on page when sidebar is sb-closed
    #main-content {
      pointer-events: none;
    }
  }

  #wrapper.sb-closed {
    #main-content {
      pointer-events: auto;
    }
  }
}

// DESKTOP STYLES BELOW 1000px
@media (max-width: 445px) {
  #admin-text {
    font-size: 0.5rem;
  }
}

// Mobile Styles
@media (max-width: 400px) {
  #sap-logo {
    max-width: 158px;
  }

  #sidebar-wrapper {
    margin-top: 4rem;

    // make margin top 8rem if greater smaller than 1000px
    @media (max-width: 1000px) {
      margin-top: 6rem; //small-screen-nav-fix
    }
  }
}