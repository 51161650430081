
#login-modal {
  #login-logo {
    max-width: 275px;
  }

  .modal-header, .modal-footer {
    border: none;
  }

  .modal-content {
    padding: 1rem 0;
  }

  .sign-in-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .btn {
      width: 75%;
    }
  }

  #forgot-password, #resend-confirmation-link {
    background: none;
    border: none;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  #remember-me-label{
    color: inherit;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
  }
}
