.place-show {

  .category-button {
    background-color: $secondary-color;
    padding-bottom: 5px;
  }

  .active-question {
    background-color: $secondary-color;
  }

  .scrollable {
    overflow-y: auto;
  }

  .modal-body {
    max-height: 60vh;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .9);

    .scrollable {
      max-height: 50vh;
      overflow-y: auto;
    }
  }


  .vh-30 {
    max-height: 30vh;
  }
}

.place-modal-body {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
}
