// grid
.grid {
  max-width: 100%;
  margin: auto;
}

// clearfix
image {
  width: 100%;
}

.card {
  padding-bottom: 0;
}

.card-image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1
}

.no-stories-text {
  text-align: center;
  margin-top: 2rem;
}
