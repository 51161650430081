
#sign-up-modal {

  .modal-header, .modal-footer {
    border: none;
    padding: 2rem
  }

  .modal-content {
    padding: 2rem;
  }

  .send-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .btn {
      width: 75%;
    }
  }
}
