.grid-card {
  margin-top: 1.5rem;

  .story-actions-links {
    a {
      padding: .3rem 1rem;
    }
  }

  .story-saved-text {
    color: #28a745;
  }

  .read-more-link {
    color: black;
    font-weight: 500;
  }

  .success-true {
    color: green;
  }

  .success-false {
    color: red;
  }
}
