#new-session-form {
  #logo {
    max-width: 300px;
  }

  .submit-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .btn {
      width: 75%;
    }
  }
}
