#map {
    height: 400px;
    z-index: 0;
}

.places-map-container {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 8rem;
    // This calculation is very important.
    // It looks weird because mobile 100vh are not
    // static. So we must account for this by subtracting
    // calc(100vh - 100%)
    height: calc(100vh - calc(100vh - 100%) - 8rem);
}

#places-map {
    height: calc(100vh - 12rem);
    min-height: 100px;
    max-height: 1000px;
    max-width: 2500px;
}

#single-place-map {
    height: 50vh;
    width: 100%;
    min-height: 100px;
    max-height: 1000px;
    max-width: 2500px;
    z-index: 1;
}

#places-map-group {
    height: 100%;
    width: 100%;
    min-height: 100px;
    max-height: 1000px;
    max-width: 2500px;
    z-index: 1;
}

.show-map-container {
    height: 100%;
}

.show-map-container-mobile {
    height: 40vh;
}

.my-story-map-container {
    height: 90vh;
    max-height: 2000px;
}

.print-map-container {
    height: 100%;
    max-height: 500px;
}

.places-map-options {
    min-height: 3.5rem;;
}

.selected-marker {
    // Changes the color of the pin to Red when clicked.
    filter: hue-rotate(120deg);
}

.places-count {
    font-size: .8rem;
    padding: 0;
    margin-right: .5rem;
    margin-left: .5rem;
}

.map-modal .modal-dialog {
    max-width: 85% !important;
}
