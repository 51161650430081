.new-user {

  .header {
    h4 {
      font-weight: normal;
    }

    #logo {
      max-width: 300px;
    }
  }

  #password-error {
    color: red;
    font-size: 0.8em;
  }

  .submit-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .btn {
      width: 75%;
    }
  }
}
