.admin-stories {
  border: 1px solid black;

  .header {
    h4 {
      font-weight: normal;
    }

    #logo {
      max-width: 300px;
    }
  }

  .table-div {
    overflow: auto;
  }

  .story-image {
    width: 2vw;
  }

  .table-text-row {
    font-size: .75rem ;
  }

  .delete-link {
    padding: .25rem .70rem;
  }

  .pagination {
    overflow: auto;

    nav {
      margin: auto;
    }
  }

  // DESKTOP STYLES ABOVE 1000px
  @media (min-width: 1000px) {
    table {
      width: 100%;
    }
  }

  @media (max-width: 999px) and (min-width: 768px) {
    table {
      width: 175%;
    }
  }

  @media (max-width: 767px) and (min-width: 400px) {
    table {
      width: 300%;
    }
  }

  @media (max-width: 399px) {
    table {
      width: 500%;
    }
  }
}
