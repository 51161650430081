.image-container {
  position: relative;
  width: 100%;
}

.video-play-icon {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #000;
  pointer-events: none;
}

.video-play-icon:hover {
  cursor: pointer;
}
