.new-story {
  padding: 2rem;
  border: 1px solid black;

  .header {
    h4 {
      font-weight: normal;
    }

    #logo {
      max-width: 300px;
    }
  }
}
