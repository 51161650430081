.recommended-places {
  .checkbox-input-group {
    border: 1px solid $secondary-color;
    border-radius: 4px;
    text-decoration: none;
    color: $secondary-color;
    color: $primary-color;
    margin: 0;
    margin-bottom: .5rem;

    :hover,
    :active {
      background-color: $secondary-color;
      color: white;
    }

    .place-title {
      padding: 4px 8px;
      color: #007bff;
      cursor: pointer;
      display: block;
    }
  }

  .rounded-top-border {
    border-top: 1px solid black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .by-line-text {
    font-size: 12px;
    font-style: italic;
    line-height: 13px;
    margin-bottom: 0;
    padding-bottom: 0;

    // Desktop
    @media (min-width: $desktop-breakpoint) {
      font-size: 13px;
    }
  }

  .place-block {
    .place-details {
      border: 1px solid $secondary-color;
      border-radius: 8px;
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .place-name {
        font-size: 12px;
      }

      .place-urls {
        max-width: 50%;

        img {
          display: flex;
          width: 20px;
        }
      }
    }

    button {
      max-width: 10%;
      color: $lighter-gray;
    }
  }

  &.share {
    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  .fixed-height {
    // Compensate for the fixed header
    height: calc(100vh - 8rem);

    &.scrollable {
      overflow-y: auto;
    }
  }

  // If screen is small, then make the map container 50vh
  @media (max-width: 768px) {
    .fixed-height {
      height: calc(50vh - 4rem);
    }
  }

  .rounded-top-border {
    border-top: 1px solid black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rounded-top-border-only-on-small-screens {
    @media (max-width: 768px) {
      border-top: 1px solid black;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-top: 1rem;
      margin: 1rem;
    }
  }

  .scrollable {
    overflow-y: auto;
  }

  .only-scrollable-on-big-screens {
    @media (min-width: 768px) {
      overflow-y: auto;
    }
  }

  .map-container {
    height: 100%;
    z-index: 1;
  }

  #recommendedPlacesMapDesktop {
    height: 100%;
    z-index: 1;
  }

  .vh-20 {
    max-height: 20vh;
  }

  //  hide on bigger screens
  .only-mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .fixed-height-only-on-big-screens {
    @media (min-width: 768px) {
      height: calc(100vh - 8rem);
    }
  }
}