#check-inbox-modal {

  .modal-header, .modal-footer {
    border: none;
  }

  .modal-content {
    padding: 1rem 0;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .btn {
      width: 75%;
    }
  }
}
